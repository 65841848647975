footer{
  background-color: #242424;
  padding: 20px;
  text-align: center;

  .footer-col{text-align: center;}


  h3{
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1.5px;
    color: #eb5142;
  }

  .footer-detail,a{
    color: $white-text;
    font-size: 10px;
    font-style: normal;
    font-stretch: normal;
    font-weight: 300;
    letter-spacing: 1.5px;
    opacity: 0.8;
  }

  a{display: block;}


  .copy-wrap{
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    padding-top: 40px;
    font-size: 10px;
    color: $white-text;

    .footer-detail{
      text-align: left;
    }
  }
  .scroll-top{
    padding-top: 35px;
    padding-bottom: 5px;

    .btn-to-top{
      position: relative;
      font-size: 12px;
      color: $white-text;

      &:before{
        content: ' ';
        display: block;
        position: absolute;
        left:-9px;
        top:-5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        opacity: 0.5;
        background-color: #eb5142;
      }
    }
  }

  @include media(">=phone-large") {
    .footer-col{
      span {
        display: block;
      }
    }
  }

  @include media(">=desktop") {


    padding: 40px 60px 30px 60px;

    .footer-col{text-align: left;}

    .copy-wrap{max-width: none;padding-left:15px;padding-right: 15px;}

    .btn-to-top{display: none;}
    .footer-col{
      span {
        display: inline;
      }
    }

    .footer-detail{
      max-width: 360px;
      font-size: 16px;

    }
  }




}