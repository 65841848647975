/* --------------------------------------------------------
Style Sheet for Food-service

version: 1.0
author: Donika Valeriy
email: valon1x@gmail.com
website: https://ua.linkedin.com/in/valeriydonika

----------------------------------------------------------*/

//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "components/utils/include-media";

@import "components/bootstrap";
@import 'https://fonts.googleapis.com/css?family=Ubuntu:300,400,700&subset=cyrillic';


//Core

body{
  font-family: 'Ubuntu', sans-serif !important;
  font-style: normal;
  font-stretch: normal;
  height: 100%!important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #000000;
}


html, body {
  margin: 0;
  height:100%;
  width:100%;
  padding:0;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.view-container{position: relative;}
.animate-show {
  opacity: 1;
  will-change: opacity;
}

.animate-show.ng-hide-add, .animate-show.ng-hide-remove {
  transition: all linear 0.5s;
}

.animate-show.ng-hide {
  opacity: 0;
}

.pag-arrow{
  background: none;
  border: none;
}
.check-element {
  padding: 10px;
  border: 1px solid black;
  background: white;
}

input.ng-invalid{
  border:1px solid #eb5142;
}



@import "components/utils/core";
@import "components/header";
@import "components/carousel";
@import "components/products-list";
@import "components/home";
@import "components/pagination";
@import "components/product-detail";
@import "components/checkout";
@import "components/404";
@import "components/contact";
@import "components/footer";
