.burger-all{
  color: #000000;
  text-decoration: none;

  sup{
    color:$red;
  }

  &:hover{
    color: #000000;
    text-decoration: underline;
  }
}

.catalog-info{
  position: relative;
  z-index: 1;
}
.row.is-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.is-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

/*
* And with max cross-browser enabled.
* Nobody should ever write this by hand.
* Use a preprocesser with autoprefixing.
*/
.row.is-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row.is-flex > [class*='col-'] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.categories-list{
  .cats-list{
    position: fixed;
    background-color: #fff;
    /*background: #fff url('/images/menu_back.jpg') no-repeat 50% 50%;*/
    background-size: contain;
    display: flex;
    align-items: center;
    opacity: 1;
    left: -100%;
    padding: 100px 20px 0;
    z-index: 30;
    top: 0;
    will-change: transform;
    overflow: auto;
    width: 100%;
    height: 100%;
    -webkit-transition: width 0.310s, opacity 0.318s, height 0.310s, ease-in-out;
    transition: transform .4s ease-in-out;
    padding-bottom: 25px;


    .close{display: none;}


    &.opened{
      transform: translate(100%,0);


      .categories-wrap{
        padding-top:50px;
      }

      .fa{
        color: #000000;
      }


      .close{
        display: block;
        position: absolute;
        top: 20px;right: 20px;
      }
      .category-item{
        margin-bottom:5px;
        a {
          color: #000000;
          text-decoration: none;
          border-bottom: 1px dashed rgba(193, 196, 199, .2);

          sup{
            color:$red;
          }
        }
      }
    }
  }
}
.products-wrap{

  .image{
    border: solid 1px #d8d8d8;
    border-radius: 1px;

    img{width: 100%;}


    a{display: block;}
  }

  .one-product{
    margin-bottom: 30px;



    .product-wrap:hover .image{
      opacity: 0.5;
    }
  }

  .product-price{
    color: #eb5142;
  }

  .product-name{
    color: #000000;
    text-decoration: none;
    font-size: 12px;
    border-bottom: 1px dashed rgba(193,196,199,.2);
    will-change: border-bottom;
    -webkit-transition: border-bottom .4s ease;-moz-transition: border-bottom .4s ease;-ms-transition: border-bottom .4s ease; -o-transition: border-bottom .4s ease;transition: border-bottom .4s ease;

    &:hover{
      color: #eb5142;
      border-bottom: 1px solid #eb5142;
    }
  }

  .products-list{
    .burger-all{display: none;}
  }

  .burger-all{padding: 15px;display: block;font-size: 13px;}
}



@include media(">=phone-large") {
  .products-wrap {
    margin: 0 auto;
    padding:0 20px;

    .image{



    }
    .col-xs-12{
      max-height: 700px;
      width: 50%;
    }
  }

  .nav-wrap{
    br{display: none;}

  }

}

@include media(">=tablet") {
  .product-wrap{
    .image{
      img{max-height: 600px;width: 100%;}
    }
  }
}

@include media(">=desktop") {
  .categories-list{
    padding: 20px 0 0 0;
    .catalog-info,.cats-list{
      height: auto;
      opacity: 1;
      display: inline-block;
      vertical-align: top;
      margin-right: -4px;
    }
  .burger-all{
    .fa-bars{display: none;}
  }

    .catalog-info{
      width: 5%;

      .burger-all{display: block;}
    }
    .cats-list{width: 92%;margin-left: 2%;position: relative;padding: 0;left: auto;will-change:auto;}

    .category-item{
      font-size:13px;
      width: auto;
      display: inline-block;
      vertical-align: top;
      padding-right: 10px;
      padding-left: 10px;

      margin-bottom:20px;

      &:not(:last-child) {
        border-right: 1px solid $red;
      }
      a{
        color:#000000;text-decoration: none;
        border-bottom: 1px dashed rgba(193,196,199,.2);
        -webkit-transition: border-bottom .4s ease;-moz-transition: border-bottom .4s ease;-ms-transition: border-bottom .4s ease; -o-transition: border-bottom .4s ease;transition: border-bottom .4s ease;

        &:hover{
          color: #eb5142;
          border-bottom: 1px solid #eb5142;
          text-decoration: none;

        }

        sup{color: $red;}

      }

    }

  }
  .products-wrap {

    .product-wrap{ }

    .burger-all{display: none;}
  }
}

@include media(">=desktop-wide") {
  .products-wrap{
    .container-fluid {
      max-width: 1530px;
      margin-left: auto;
      margin-right: auto;
    }
    padding: 0 60px;

    .col-md-3 {
      width: 25%;
    }

    .product-name, .product-price{
      font-size:23px;
    }
  }

  .categories-list{
    .category-item,.burger-all{
      font-size: 14px;
    }

    .burger-all{
      font-size: 14px;
      display: block;
      padding: 0;
    }

    .catalog-info{width: 5%;}
    .cats-list{width: 94.5%;margin-left: 0.5%; }

    padding: 20px 60px;
  }



}

.seo-text{color: #000 !important;}