.checkout{
  .update-count{
    input,button{display: inline-block;vertical-align: middle;margin-right: -4px;height: 28px;}
    button{
      background: $red;border: none;color:#fff; padding: 0 10px;
      &:disabled{
        opacity:0.5;
      }

    }
  }
  .desk-show{display: none;}
  .back-desktop{display: none;}
  .checkout-category{
    display: none;
    font-size: 11px;
    a{color: #b2b2b2!important;}
  }
  .last-second{
    .checkout-remove{
      display: none;
    }

  }
  .border-anchor{
    border-bottom: 1px solid rgba(193,196,199,.6);
    -webkit-transition: border-bottom .4s ease;-moz-transition: border-bottom .4s ease;-ms-transition: border-bottom .4s ease; -o-transition: border-bottom .4s ease;transition: border-bottom .4s ease;

    &:hover{
      color: #eb5142;
      border-bottom: 1px solid #eb5142;
      text-decoration: none;
    }
  }

  .cart-list{

    &__one-col{
      display: inline-block;vertical-align: top;margin-right: -4px;width: 45%; font-size: 12px;

      &--second{margin-left:11%;text-align: right;}
    }
    .one-item{
      position: relative;
      display: block;
      padding: 10px 15px;
    }
    .total-one{margin-right: 25px;}
    &__image{
      width: 50px;
      height: 50px;
      margin-bottom: 10px;
      img{
        border:1px solid $grey;
        max-width: 50px;
        width: 100%;
        height: 50px;
      }
    }

    .checkout-input{
      font-size: 14px;
      width: 50px;
      height: 28px;
      border-radius: 0;
      text-align: center;

    }


    .checkout-remove{
      padding: 0;
      color: $red;
      font-size: 12px;
    }

    &__title{
      width: 51%;
      margin-left:7%;
      a{
        color:#000000;
        font-size: 12px;
        text-decoration: none;
      }

    }

    &__image, &__title{
      display: inline-block;vertical-align: top;
    }
  }
  .total-price{
    background-color: #d8d8d8;
    padding: 10px 15px;
  }

  .hide-order{color:$red;}
  .form-wrap{
    padding: 10px 15px;

    input, button{border-radius: 0;}
    .btn-order{
      width: 100%;
      color:#fff;
      background: $red;
    }
    .back-shop{
      margin-top:20px;
      display: block;color:#000000;

      img{margin-right: 5px;}
    }
  }

}
@include media('>=phone-large'){
  .checkout{
    .cart-list{
      &__one-col{width: 44%;}
    }
  }
}
@include media('>=tablet'){
  .checkout{
    padding:60px 20px 0 20px;
    .checkout-category{display: block;}


    .total-price{display: none;}
    .items-wrap{max-width: none;}

    .form-wrap{
      label{display: block;}
      .form-group,.btn-wrap{
        width: 33%;
        display: inline-block;
        vertical-align: bottom;
      }

      input, button{width: 100%;}
    }

    .form-width{
      max-width: 380px;
      width: 100%;

    }
  }
}

@include media('>=desktop'){
  .checkout {
    .desk-show {
      display: block;
    }
  }

}

@include media(">=desktop-wide") {
  .checkout {
    .cart-list__one-col {
      width: 25%;
      margin-left:0;
      vertical-align: middle;
      text-align: left;
      position: relative;

      &.first{text-align: left;}
      .mobile-remove{display: none;}
    }


    .last-second{
      .checkout-remove{display: block;width: 100%;}
    }


    .desk-half{
      width: 50%;
      display: inline-block;
      vertical-align: middle;
      margin-right: -4px;
      margin-bottom: 0;
    }

    .back-desktop{display: block;}
    padding: 120px 60px 60px 60px;

    .container-fluid{
      padding-left: 110px;
      padding-right: 110px;
    }

    .form-wrap .back-shop{display: none;}

    .cart-list{
      .total-one{
        display: none;
      }
      .one-item{
        border-bottom:1px solid $grey;
        padding: 10px 0;
        margin: 0 15px;
      }
    }
    .desktop-remove-btn{
      position: absolute;
      right: 0;
    }


    h1{
      font-weight: bold;
    }



    .title{
      position: relative;
      margin-bottom: 35px;
      a{
        position: absolute;
        left: 0;
        line-height: 69px;color:#000000;
        text-decoration: none;
        img{margin-right: 5px;}
      }

      span{
        border-bottom: 1px solid rgba(193,196,199,.2);
        will-change: border-bottom;
        -webkit-transition: border-bottom .4s ease;-moz-transition: border-bottom .4s ease;-ms-transition: border-bottom .4s ease; -o-transition: border-bottom .4s ease;transition: border-bottom .4s ease;

        &:hover{
          color: #eb5142;
          border-bottom: 1px solid #eb5142;
        }
      }
    }

    .items-wrap{
      a{
        border-bottom: 1px solid rgba(193,196,199,.2);
        will-change: border-bottom;
        -webkit-transition: border-bottom .4s ease;-moz-transition: border-bottom .4s ease;-ms-transition: border-bottom .4s ease; -o-transition: border-bottom .4s ease;transition: border-bottom .4s ease;

        &:hover{
          color: #eb5142;
          border-bottom: 1px solid #eb5142;
        }
      }
    }
    .desk-show{border-bottom: 1px solid $grey;margin: 0 15px;padding-bottom: 10px;}
    .one-check-col{
      display: inline-block;
      vertical-align: middle;
      width: 25%;
      margin-right: -4px;
      text-align: left;
    }
  }


}