.add-cart-wrap {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
  border: solid 1px $red;
  font-size: 0;

  input, .add-cart-btn {
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
  }

  input {
    font-size: 14px;
    width: 15%;
    border: none;
    border-radius: 0;
    text-align: center;
  }

  .add-cart-btn {
    font-size: 14px;
    border-radius: 0;
    width: 85%;
    background-color: $red;
    color: white;
  }

}
.product-detail {
  position: relative;
  z-index: 1;
  .product-images, .description {
    width: 100%;
    padding: 0 15px;
  }
  .out-of-stock {
    margin-bottom: 20px;
    text-align: center;
    border: 1px solid #EB5140;
    width: auto;
    padding: 4px;
  }

  .product-images {
    position: relative;
    padding-top: 20px;
    text-align: center;
  }

  .title,.product-price {
    font-size: 20px;
  }

  .main-image{padding-top: 45px;}

  .related-products{display: none;}

  .images-arrow {
    position: absolute;
    top: 20px;
    left: 50%;
    margin-left: -33px;
    height: 26px;
    width: 66px;
    text-align: center;
  }

  .arrow-left, .arrow-right {
    -webkit-transition: opacity .2s ease-out;
    transition: opacity .2s ease-out;
    font-size: 2rem;
    position: absolute;
    opacity: .75;
    cursor: pointer;
    width: 26px;
    height: 26px;
    top: 0;
    background-size: contain;

    &:before {
      content: '';
    }
  }

  .arrow-left {
    background: url('/images/border-arrow-left.svg') no-repeat 100% 50%;
    background-size: cover;
    left: 0;
  }

  .arrow-right {
    background: url('/images/border-arrow-right.svg') no-repeat 100% 50%;
    background-size: cover;
    right: 0;
  }

  .advanced-desc{
    margin-top:25px;
    margin-bottom:40px;

    h3{
      font-size:14px;
    }
  }

  table{width: auto !important;}

  .thumbnails{
    padding-top: 25px;
    text-align: left;

    .one-thumb{
      display: inline-block;vertical-align: top;width:95px;height: 95px;margin-left:5px; opacity: 0.5;cursor: pointer;border:1px solid $red;
      &:first-child{margin-left: 0;}
      &.selected{opacity:1;}

      img{padding: 4px;width: 93px;height: 93px;}

    }
  }

}

@include media(">=tablet") {
  .product-detail {

    .pr-desc-col{
      display: inline-block;vertical-align: middle;margin-right: -4px;width: 50%;
    }
    .main-image{}

    article{
      .tabs-wrap{
        margin-bottom:30px;
      }
      .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus{
        border:none;


      }

      .nav-tabs{
         li.active{
          a{font-weight: bold;}
        }
      }

      a.nav-link{
        text-decoration: underline;
      }



      .nav-tabs {
        padding-top: 25px;
        border-bottom: none;
        color: #000000;

        a {
          border: none;
          color:#000000;
          padding-left: 0;
        }
      }
    }

  }
}

@include media(">=desktop") {
  .product-detail {
    padding-top:60px;
    .product-images, .description {
      vertical-align: top;
      display: inline-block;
      margin-right: -4px;
    }
    .product-images{
      width: 55%;
    }
    .description {width: 40%;padding-left: 5%;}

    .pr-desc-col{
      display: block;width: 100%;
    }
    .main-image{padding-top: 0;}

    /*.images-arrow{
      left: auto;
      right: 20px;
      margin-left: 0;
    }*/
    .images-arrow {display: none;}
    .product-image{float:left;width: 74%;}
    .thumbnails{
      float: right;
      width: 25%;
      padding-top: 0;
      /*padding-top: 40px;*/

      .one-thumb{display: block;margin-left:0;overflow: hidden;margin-bottom:5px;}
    }
    .related-products{
      position: relative;
      margin-top: 50px;

      &__title{
        position: relative;
        margin: 0 auto;
        text-align: center;
      }

      .container-fluid{padding: 40px 0 80px 0;}
      .product-wrap{max-width: 330px;width: 100%;}


      h2{
        width: 100%;
        text-align: center;
        border-bottom: 1px solid $grey;
        line-height: 0.1em;
        margin: 10px 0 20px;

        span { background:#fff;padding:0 35px; }
      }

    }
  }
}

@include media(">=desktop-wide") {
  .product-detail {
    padding-top:80px;

    .related-products{display: block;}



  }
}

@include media(">=desktop-large") {
  .product-image{max-width: 740px;}
}
