body.no-scroll {
  overflow: hidden;
}

header {
  z-index: 2;
  padding-bottom:5px;
  border-bottom:solid 1px $grey;
  &.home{
    /*height: 100vh;*/
  }


  .section{
    background-color: #f6f6f6;
  }


  .nav-wrap{
    padding: 0 20px;
    text-align: center;


    .first-li{margin-right: 10px;}
    .second-li{margin-left:10px;}
    .other-li{margin: 0 10px;}
    .last-el{margin-top:5px;}


    .logo{
      a{display: none;font-size: 0;}
    }
  }

  ul{
    a{
      color:#000000;
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      border-bottom: 1px dashed rgba(193,196,199,.2);
      -webkit-transition: border-bottom .4s ease;-moz-transition: border-bottom .4s ease;-ms-transition: border-bottom .4s ease; -o-transition: border-bottom .4s ease;transition: border-bottom .4s ease;

      &:hover{
        color: #eb5142;
        border-bottom: 1px solid #eb5142;
        text-decoration: none;
      }
      sup{color:#eb5142;}

    }

  }




  .red-logo-wrapper{
    padding: 20px 0;
    text-align: center;
    margin: 0 auto;
  }




}
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation : portrait) {
    header {
      height: auto;

      .nav-wrap{
        a{font-size: 16px;}
      }
    }
  }

  @include media(">=phone-large") {
    header {
      height: auto;

      .nav-wrap{
        a{font-size: 16px;}
        .last-el{margin-top:0;}
      }
    }
    header.home{height: auto;}
  }

@media screen and  (min-width: 400px) {
  header.home{height: auto;}
}

  @include media(">=desktop-wide") {

    header {
      border-bottom: none;
      .full-desktop{/*height: 100vh;*/}
      .red-logo-wrapper{display: none;}


      .nav-wrap {
        position: relative;
        text-align: left;
        padding: 12px 60px;


        border-bottom:solid 1px $grey;

        ul{margin-bottom:0;}
        .second-li{float:right;}

        .logo{
          a{
            display: block;
            background: url('/images/logos/food-service-logo.svg') no-repeat 100% 50%;
            background-size: cover;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top:4px;
            width: 120px;
            height: 32px;
          }

        }
      }

      &.home{
        .nav-wrap {
          /*height: 5vh;*/
          height: auto;
        }
      }

    }
    header.category-page{
      position: fixed;
      height: 46px;
      z-index: 5;
      top: 0;
      background: #fff;

      width: 100%;
      .nav-wrap{


        &:after{
          background: url('/images/logos/food-service-logo-black.svg') no-repeat 100% 50%;
          background-position: center;
        }
      }
    }

    .category-page-main{
      margin-top:66px;
    }


  }
