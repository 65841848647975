input[type=range]{width:300px}ul[rn-carousel]{overflow:hidden;padding:0;white-space:nowrap;position:relative;-webkit-perspective:1000px;-ms-perspective:1000px;perspective:1000px;-ms-touch-action:pan-y;touch-action:pan-y}ul[rn-carousel]>li{color:#000;-webkit-backface-visibility:hidden;-ms-backface-visibility:hidden;backface-visibility:hidden;overflow:visible;vertical-align:top;position:absolute;left:0;right:0;white-space:normal;padding:0;margin:0;list-style-type:none;width:100%;height:100%;display:inline-block}ul[rn-carousel-buffered]>li{display:none}ul[rn-carousel-transition=hexagon]{overflow:visible}div.rn-carousel-indicator span{cursor:pointer;color:#666}div.rn-carousel-indicator span.active{color:#fff}.rn-carousel-control{-webkit-transition:opacity .2s ease-out;transition:opacity .2s ease-out;font-size:2rem;position:absolute;top:40%;opacity:.75;cursor:pointer}.rn-carousel-control:hover{opacity:1}.rn-carousel-control.rn-carousel-control-prev{left:.5em}.rn-carousel-control.rn-carousel-control-prev:before{content:"<"}.rn-carousel-control.rn-carousel-control-next{right:.5em}.rn-carousel-control.rn-carousel-control-next:before{content:">"}
.slider{
  .slide-image{
    height: 100%;
    background-size: cover;
    background-repeat:no-repeat;
    background-position: 100% 50%;
  }
  padding-top: 90px;
  .slide-back{

  }
  &__list{
    width: 100%;
    height: 350px;
    &:after{
      content: '';
      display: block;
      bottom: 0;
      position: absolute;
      left:0;
      right: 0;
      width:100%;
      z-index: 1;
      height: 75px;
      background: url('/images/orange-line.png') no-repeat 100% 50%;
      background-size: cover;
    }


    .slide{
      /*background: url('/images/slides/image.png') no-repeat 100% 50%;
      background-size: cover;
      background-repeat:no-repeat;
      background-position: 100% 50%;*/
    }
  }

  .rn-carousel-control {
    width:26px;
    height: 26px;
    top:33px;
    background-size: contain;

    &:before{
      content:'';
    }
  }
  .slider_pagination{
    text-align: center;
    position: absolute;
    left: 50%;
    top: 36px;
    margin-left:-17px;
  }

  .slide-wrap{

    display: none;

    p{display: none;}
  }
}


.rn-carousel-control.rn-carousel-control-prev{
  background: url('/images/border-arrow-left.svg') no-repeat 100% 50%;
  left: 103px;
  background-size: cover;
}
.rn-carousel-control.rn-carousel-control-next{
  background: url('/images/border-arrow-right.svg') no-repeat 100% 50%;
  right: 103px;
  background-size: cover;
}


@include media(">=phone-large") {
  .slider{
    padding: 0;


    .slider_pagination{right: 66px;left: auto;}
  }

  .slider_pagination{
    color:$white-text;
  }


  .rn-carousel-control.rn-carousel-control-prev{
    background: url('/images/border-arrow-left-white.svg') no-repeat 100% 50%;
    background-size: cover;
    right: 110px;
    left: auto;
  }
  .rn-carousel-control.rn-carousel-control-next{
    background: url('/images/border-arrow-right-white.svg') no-repeat 100% 50%;
    background-size: cover;
    right: 30px;

  }
}

@include media(">=phone-large-portrait") {

  .slider {
    &__list {
      height: 200px;
      font-size: 10px;
    }
  }
}

@include media(">=tablet") {
  .slider {
    &__list {
      height: 320px;


    }

    .slide-wrap {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      width: 60%;
      margin: 0 auto;
      color: $white-text;
      top: 50%;
      margin-top: -90px;

      img {
        width: 100%;
      }
      p {
        margin-top:5px;
        line-height: 1.56;
        letter-spacing: 2.7px;
        opacity: 0.8;
        text-align: left;
      }
    }
  }
}






@include media(">=desktop") {
  .slider {
    &__list {
      height: 500px;

      &:after{
        height: 150px;
      }
    }

    .slide-wrap {
      margin-top: -150px;
      p {
       display: block;
      }
    }


  }
}

@include media(">=desktop-wide") {
  .slider {
    &__list {
      margin-bottom: 0;
      height: 95vh;
      font-size: 10px;

      &:after{
        height: 211px;
      }

      &.scrolled{
        height: 500px;
      }
    }

    .slide-wrap {
      width: 30%;
      margin-top: -150px;

    }
  }
}