.contact-page{
  margin-top: 46px;
  text-align: left;
  font-size: 20px;


  .work-hours{
    opacity: 0.8;
    color: #b2b2b2;
  }


  a{
    color:#000000;
    display: block;
  }
  #map {
    margin: 80px 0;
    height: 500px;
    width: 100%;
  }

  .contact-phones{
    max-width: 250px;
  }
}
.payment-text{padding-top: 25px;}
@include media(">=tablet") {
  .bot{margin-top:40px;}
  .bot-left{margin-top: 70px;}
}
@include media(">=desktop") {
  main.contact-page{
    .container-fluid{
      padding-top: 80px;
      padding-left: 110px;
      padding-right: 110px;
    }
  }

}