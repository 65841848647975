.pagination-wrap{
  display: block;
  margin: 0 auto;
  width: 100%;
}
.pagination{
  width: 100%;
  font-size: 11px;
  text-align: center;
  padding: 0 15px;

  .opacity{opacity: 0;
    cursor: default;}
  .text-arrow{
    color: #000000;
    margin:0 2px;
  }

  img{width: 20px;}
  .pagination-item,.pull-left,.pull-right{display: inline-block;vertical-align: middle;}
  .middle {display: none;}
  a{
    color:$red;
  }
}
@include media('>=phone-large'){
  .pagination {
    .middle {
      display: inline-block;
      margin: 0 auto;
      vertical-align: middle;

      .hide-mob {
        display: none;
      }

    }
    .va-middle {
      line-height: 43px;
    }
  }
}
@include media(">=tablet") {
  .pagination{
    font-size: 14px;
    img{width: 45px;}
  }
}


@include media(">=desktop") {
  .pagination{
    font-size: 16px;
    img{width: 60px;}
    .middle{display: inline-block;}
  }
}